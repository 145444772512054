import axios from "axios";

// 
import axiosOptions from '../../helpers/axiosOptions'
import Const from '../../helpers/const'

// FUNCTION to Fetch Selected Forum's Question's Answer
export function forumQuestionDetails(questionObject) {
    return async (dispatch) => {

        try {
            // Step:1 Try to fetch answer from backend API
            let data = { 'question_id': questionObject.id }
            console.log('iddddd',questionObject.id);
            const response = await axios.post(Const.BASE_URL + 'get-GetAiAnswer', data, axiosOptions(localStorage.userToken));
            console.log('response', response);

            // Check if API response successfull
            if (response?.data?.message === 'success' && response?.data?.status === 'true') {
                if (
                    // Now check if response.data is null
                    response?.data?.data === null ||
                    response?.data?.ai_answer ===
                    "I'm sorry, I'm not sure what you're asking for. Can you please provide more context or information?"
                ) {
                    // Step:2 Try to fetch answer from Chat GPT
                    console.log('selected question data', questionObject);
                    const response = await axios.post(
                        'https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview',
                        {
                            stop: null,
                            top_p: 0.95,
                            temperature: 0.7,
                            max_tokens: 2000,
                            presence_penalty: 0,
                            frequency_penalty: 0,
                            messages: [{ "role": "user", "content": questionObject.subject }],
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'api-key': '7327443ae85e44419192e4fb0544d702',
                            },
                        },
                    );
                    // Check if successfull response from ChatGpt 
                    if (response) {
                        const assistantResponse = { 'ai_answer': response.data.choices[0].message.content }
                        dispatch({
                            type: 'FORUM_FEEDBACK_ANSWER',
                            payload: assistantResponse,
                        });
                        //  Save Chat GPT response in backend Database
                        let responseObject = {
                            'user_id': questionObject.created_by,
                            'question_id': questionObject.id,
                            'ai_answer': response.data.choices[0].message?.content,
                        }
                        const responseSaveAPI = await axios.post(Const.BASE_URL + 'save-airesponse', responseObject, axiosOptions(localStorage.userToken),
                        );
                    }
                } else {
                    dispatch({
                        type: 'FORUM_FEEDBACK_ANSWER',
                        payload: response.data.data,
                    });
                }
            }
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

// FUNCTION to Fetch Selected Academic-English Question's Answer
export function academicQuestionDetails(questionObject) {

    return async (dispatch) => {

        try {
            // Step:1 Try to fetch answer from backend API
            let data = { 'question_id': questionObject.id}
            const response = await axios.post(Const.BASE_URL + 'get-GetAiAnswer', data, axiosOptions(localStorage.userToken));
            // Check if API response successfull
            if (response?.data?.message === 'success' && response?.data?.status === 'true') {
                if (
                    // Now check if response.data is null
                    response?.data?.data === null ||
                    response?.data?.ai_answer ===
                    "I'm sorry, I'm not sure what you're asking for. Can you please provide more context or information?"
                ) {
                    // Step:2 Try to fetch answer from Chat GPT
                    const response = await axios.post(
                        'https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview',
                        {
                            stop: null,
                            top_p: 0.95,
                            temperature: 0.7,
                            max_tokens: 2000,
                            presence_penalty: 0,
                            frequency_penalty: 0,
                            messages: [{ "role": "user", "content": questionObject.subject }],
                        },
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'api-key': '7327443ae85e44419192e4fb0544d702',
                            },
                        },
                    );
                    // Check if successfull response from ChatGpt 
                    if (response) {
                        const assistantResponse = { 'ai_answer': response.data.choices[0].message.content }
                        dispatch({
                            type: 'ACADEMIC_FORUM_QUESTION_DETAILS',
                            payload: assistantResponse,
                        });
                        //  Save Chat GPT response in backend Database
                        let responseObject = {
                            'user_id': questionObject.created_by,
                            'question_id': questionObject.id,
                            'ai_answer': response.data.choices[0].message?.content,
                        }
                        const responseSaveAPI = await axios.post(Const.BASE_URL + 'save-airesponse', responseObject, axiosOptions(localStorage.userToken),
                        );
                    }
                } else {
                    dispatch({
                        type: 'ACADEMIC_FORUM_QUESTION_DETAILS',
                        payload: response.data.data,
                    });
                }
            }
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

export function forumFeedbackList(data) {
    return async (dispatch) => {
        // try {
        try {
            const response = await axios.post(Const.BASE_URL + 'forum-feedback/get-feedback-list', data, axiosOptions(localStorage.userToken));
            dispatch({
                type: 'FORUM_FEEDBACK_LIST',
                payload: response.data.output,
            });
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

export function forumFeedbackAnswer(data) {
    return async (dispatch) => {
        // try {
        try {
            const response = await axios.post(Const.BASE_URL + 'forum-feedback/add-feedback', data, axiosOptions(localStorage.userToken));
            dispatch({
                type: 'FORUM_FEEDBACK_ANSWER',
                payload: response.data.output,
            });
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}

export function forumFeedbackLike(data) {
    return async (dispatch) => {
        // try {
        try {
            const response = await axios.post(Const.BASE_URL + 'forum-feedback/like-feedback', data, axiosOptions(localStorage.userToken));
            return await Promise.resolve({
                type: 'success',
                payload: response.data.output
            });
        } catch (error) {
            let err = (error.response) ? error.response.data : error;
            dispatch({
                type: 'FORUM_FEEDBACK_ERROR',
                payload: err,
            });
            return await Promise.resolve({
                type: 'error',
                payload: err,
            });
        }
    }
}