import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../../components";
import moment from "moment";
import WalletComponents from "../wallet/WalletComponents";
import Topup from "../wallet/Topup";
import CloseIcon from "@material-ui/icons/Close";
import { Fonts, BgColor, Color } from "../../theme/index";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QRCode from "react-qr-code";
// import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import { object } from "underscore";
import { Obj } from "prelude-ls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { Subscription } from "..";
import Const from "../../helpers/const";
import axios from "axios";
import axiosOptions from "../../helpers/axiosOptions";
import { ArrowForward } from "@material-ui/icons";

import {
  Grid,
  Box,
  TextField,
  Input,
  Checkbox,
  Button,
  Card,
  Container,
  CardContent,
  Typography,
  FormControl,
} from "@material-ui/core";
import {
  getQRcode,
  walletPay,
  isUseWallet,
  cancelsubscriptioncall,
} from "../../redux/actions/paymentActions";
import { checkAuth } from "../../redux/actions/authActions";
import { aiHumanChat } from "../../assets";

const styles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    paddingLeft: 250,
    paddingTop: 70,
    paddingBottom: 20,
    textAlign: "center",
  },
  root: {
    maxWidth: 345,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 140,
  },
  mainHeading: {
    fontSize: "30px",
    fontFamily: Fonts.Medium,
  },
  blueButton: {
    color: "#fff",
    height: "40px",
    borderRadius: "5px",
    backgroundColor: BgColor.myBlue,
    padding: "0 20px",
    "&:hover": {
      color: Color.textBlue,
      border: "1px solid" + Color.textBlue,
    },
  },
  orText: {
    color: Color.myBlack,
    fontSize: "18px",
    fontFamily: Fonts.Medium,
    opacity: ".5",
    justifyContent: "space-between",
  },
  payServiceDiv: {
    width: "100%",
    padding: "0px 0px",
    borderRadius: "0",
    backgroundColor: "#ECF2FF",
    boxShadow: "none",
    marginTop: "10px",
  },
  center: {
    display: "inline-block",
    position: "relative",
  },
  smallLightText: {
    color: BgColor.myBlack,
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    opacity: 0.2,
  },
  overlayWrapper: {
    position: "fixed",
    top: "0",
    left: "0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  mainHeading2: {
    fontSize: "25px",
    fontFamily: Fonts.Medium,
  },
  whiteOutlinedButton: {
    width: "100%",
    height: "40px",
    color: Color.textBlue,
    fontFamily: Fonts.Medium,
    borderRadius: "5px",
    backgroundColor: "#fff",
    border: "2px solid " + BgColor.myBlue,
    padding: "0 20px",
    margin: "5px 0",
  },
  marginTop: {
    marginTop: "20px",
  },
}));

const Subscription = () => {
  const { t, i18n } = useTranslation();
  const classes = styles();
  const history = useHistory();

  const gotoPlans = () => {
    window.location.href = "/subscription/plans";
  };

  return (
    <>
      <Header history={history} />
      <Container
        maxWidth="md"
        className={classes.container}
        style={{ marginTop: 10 }}
      >
        <Card
          className={styles.payCard}
          style={{ paddingBottom: 10, paddingTop: 10 }}
        >
          <Box display="flex" justifyContent="center" flexDirection="row">
            <img src={aiHumanChat} style={{ height: "110px", width: "auto" }} />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            gridGap={20}
            alignItems="center"
          >
            <Typography
              variant="h5"
              component="h5"
              className={classes.mainHeading}
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/subscription/plans")}
            >
              {t('explore_genie_plans')}
            </Typography>
            <Typography
              variant="h5"
              component="h5"
              className={classes.mainHeading}
              style={{ cursor: "pointer" }}
              onClick={() => history.push("/subscription/plans")}
            >
              <ArrowForward />
            </Typography>
          </Box>
        </Card>
      </Container>
    </>
  );
};

export default Subscription;
