import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../../components";
import moment from "moment";
import WalletComponents from "../wallet/WalletComponents";
import Topup from "../wallet/Topup";
import CloseIcon from "@material-ui/icons/Close";
import { Fonts, BgColor, Color } from "../../theme/index";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QRCode from "react-qr-code";
// import FormControl from '@material-ui/core/FormControl';
import FormLabel from "@material-ui/core/FormLabel";
import { object } from "underscore";
import { Obj } from "prelude-ls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { Subscription } from "..";
import Const from "../../helpers/const";
import axios from "axios";
import axiosOptions from "../../helpers/axiosOptions";
import { ArrowForward } from "@material-ui/icons";
import {
  Grid,
  Box,
  TextField,
  Input,
  Checkbox,
  Button,
  Card,
  Container,
  CardContent,
  Typography,
  FormControl,
} from "@material-ui/core";
import {
  getQRcode,
  walletPay,
  isUseWallet,
  cancelsubscriptioncall,
  updateQRCode,
} from "../../redux/actions/paymentActions";
import { checkAuth } from "../../redux/actions/authActions";
import { aiHumanChat, goldenLamp, subscriptionSvg } from "../../assets";
import PayModal from "./PayModal";

const styles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    paddingLeft: 250,
    paddingTop: 70,
    paddingBottom: 20,
    textAlign: "center",
  },
  root: {
    maxWidth: 345,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 140,
  },
  mainHeading: {
    fontSize: "30px",
    fontFamily: Fonts.Medium,
  },
  blueButton: {
    color: "#fff",
    height: "40px",
    borderRadius: "5px",
    backgroundColor: BgColor.myBlue,
    padding: "0 20px",
    "&:hover": {
      color: Color.textBlue,
      border: "1px solid" + Color.textBlue,
    },
  },
  orText: {
    color: Color.myBlack,
    fontSize: "18px",
    fontFamily: Fonts.Medium,
    opacity: ".5",
    justifyContent: "space-between",
  },
  payServiceDiv: {
    width: "100%",
    padding: "0px 0px",
    borderRadius: "0",
    backgroundColor: "#ECF2FF",
    boxShadow: "none",
    marginTop: "10px",
  },
  center: {
    display: "inline-block",
    position: "relative",
  },
  smallLightText: {
    color: BgColor.myBlack,
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    opacity: 0.2,
  },
  overlayWrapper: {
    position: "fixed",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  mainHeading2: {
    fontSize: "25px",
    fontFamily: Fonts.Medium,
  },
  whiteOutlinedButton: {
    width: "100%",
    height: "40px",
    color: Color.textBlue,
    fontFamily: Fonts.Medium,
    borderRadius: "5px",
    backgroundColor: "#fff",
    border: "2px solid " + BgColor.myBlue,
    padding: "0 20px",
    margin: "5px 0",
  },
  marginTop: {
    marginTop: "20px",
  },
}));

const SubscriptionPlans = () => {
  const { t, i18n } = useTranslation();
  const classes = styles();
  const history = useHistory();
  const [plans, setPlans] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const authUser = useSelector((store) => store.auth.user);
  const [subsPrice, setSubsPrice] = useState("");
  const [plan, setPlan] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    checkActivePlan();
  }, [authUser]);

  const getGeniePlans = async () => {
    try {
      const response = await axios.get(
        Const.BASE_URL + "ai-subscription-plans",
        axiosOptions(localStorage.userToken)
      );
      setPlans(response.data.output);
    } catch (error) {
      let err = error.response ? error.response.data : error;
      console.log("Error in genie plans:", err);
    }
  };

  const checkActivePlan = async () => {
    try {
      const response = await axios.post(
        Const.BASE_URL + "check_genie_status",
        { user_id: authUser?.id },
        axiosOptions(localStorage.userToken)
      );
      if (response && response?.data?.status === "false") {
        setActivePlan(null);
        getGeniePlans();
      } else {
        setActivePlan(response?.data?.data);
      }
    } catch (error) {
      let err = error.response ? error.response.data : error;
      console.log("Error in checking active plan:", err);
    }
  };

  const subscribePlan = async () => {
    alert("hello");
  };

  const openPayCard = () => {
    dispatch(updateQRCode(""));
    setOpenModal(false);
  };

  return (
    <>
      <Header history={history} />

      {/* Payment Modal */}
      {openModal ? (
        <Container
          maxWidth="xl"
          style={{
            position: "fixed",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
            zIndex: "2000",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <PayModal plan={plan} openPayCard={openPayCard} authUser={authUser} />
        </Container>
      ) : (
        ""
      )}

      <Container
        maxWidth="md"
        className={classes.container}
        style={{ marginTop: 10 }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: 10 }}
        >
          <img src={goldenLamp} style={{ height: "110px", width: "auto" }} />
          <Typography
            component="p"
            variant="body2"
            color="textSecondary"
            className={classes.title}
            style={{ fontWeight: "bold" }}
          >
            {t("powered by chatgpt 4 turbo")}
          </Typography>
        </Box>

        {/* If user has not subscribed, then show the plans */}
        {!activePlan &&
          plans.length > 0 &&
          plans.map((plan, index) => (
            <Card
              className={styles.payCard}
              style={{ paddingBottom: 10, paddingTop: 10, marginBottom: 20 }}
              key={index}
            >
              <Grid container>
                <Grid item sm={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="start"
                    style={{ paddingLeft: 20, paddingRight: 20 }}
                    onClick={() => setOpenModal(true) + setPlan(plan)}
                  >
                    <Typography
                      className={classes.title}
                      variant="h6"
                      noWrap
                      style={{ cursor: "pointer" }}
                    >
                      {`${index + 1}- ${t(plan.title)}`}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textSecondary"
                      className={classes.title}
                    >
                      {`${t('validity')}: ${plan.vaild_upto} (${plan.vaild_upto_hr})`}
                    </Typography>
                    <Typography
                      component="p"
                      variant="body2"
                      color="textSecondary"
                      className={classes.title}
                    >
                      {`${t('tokens')}: ${plan.words_lenght}`}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sm={6}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    style={{
                      paddingLeft: 10,
                      paddingRight: 10,
                      height: "100%",
                    }}
                    onClick={() => setOpenModal(true) + setPlan(plan)}
                  >
                    <Typography
                      component="p"
                      variant="body2"
                      color="textSecondary"
                      className={classes.title}
                      style={{ color: Color.textBlue, cursor: "pointer" }}
                    >
                      {`${plan.price} (¥)`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          ))}

        {/* Else, show the active plan details */}
        {activePlan && (
          <Card className={styles.payCard} style={{ padding: 10 }}>
            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                component="h5"
                variant="h5"
                color="textSecondary"
                className={classes.title}
                style={{ color: Color.textBlue }}
              >
                Active
              </Typography>
            </Box>
            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ fontWeight: "bold" }}
              >
                PlanID:
              </Typography>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ color: Color.textBlue }}
              >
                {activePlan?.plan_id}
              </Typography>
            </Box>
            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ fontWeight: "bold" }}
              >
                Subscribed On:
              </Typography>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ color: Color.textBlue }}
              >
                {moment(activePlan?.updated_at).format("yyyy-MM-DD hh:mm a")}
              </Typography>
            </Box>
            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ fontWeight: "bold" }}
              >
                Plan Valid Up To:
              </Typography>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ color: Color.textBlue }}
              >
                {moment(activePlan?.vaild_upto).format("yyyy-MM-DD hh:mm a")}
              </Typography>
            </Box>
            <Box style={{ display: "flex", gap: 10, alignItems: "center" }}>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ fontWeight: "bold" }}
              >
                Remaining Tokens:
              </Typography>
              <Typography
                component="p"
                variant="body2"
                color="textSecondary"
                className={classes.title}
                style={{ color: Color.textBlue }}
              >
                {activePlan?.words_lenght}
              </Typography>
            </Box>
          </Card>
        )}
      </Container>
    </>
  );
};

export default SubscriptionPlans;
