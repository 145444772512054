//Wechat APIS
export const wechat_appID = "wx8b25c6e83f785918";
export const wechat_sns_url = "https://api.weixin.qq.com/sns/";
export const app_id = "wx8b25c6e83f785918";
//export const secret_key = "ea20bc1d163f9cd196097a12a70c81e0";
export const secret_key = "b243f30c607dc5a635efad699c838f49";

//WECHAT APIS

export const backend = "https://app.taoke.run/";
// export const backend = "http://192.168.18.177:4000/";
// export const backend = "https://tvshopee.herokuapp.com/";

export const SOCKET_URL = "https://irevu.asia";