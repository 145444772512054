import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../../components";
import moment from "moment";
import WalletComponents from "../wallet/WalletComponents";
import Topup from "../wallet/Topup";
import CloseIcon from "@material-ui/icons/Close";
import { Fonts, BgColor, Color } from "../../theme/index";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import QRCode from "react-qr-code";
import FormLabel from "@material-ui/core/FormLabel";
import { object } from "underscore";
import { Obj } from "prelude-ls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Const from "../../helpers/const";
import axios from "axios";
import axiosOptions from "../../helpers/axiosOptions";
import { ArrowForward } from "@material-ui/icons";
import { Send } from "@mui/icons-material";
import {
  Grid,
  Box,
  TextField,
  Input,
  Checkbox,
  Button,
  Card,
  Container,
  CardContent,
  Typography,
  FormControl,
  IconButton,
} from "@material-ui/core";
import { now } from "moment/moment";

const styles = makeStyles(() => ({
  container: {
    alignSelf: "flex-end",
    paddingLeft: 250,
    paddingTop: 70,
    paddingBottom: 20,
    textAlign: "center",
  },
  root: {
    maxWidth: 345,
    marginTop: 10,
    marginRight: 10,
  },
  media: {
    height: 140,
  },
  mainHeading: {
    fontSize: "30px",
    fontFamily: Fonts.Medium,
  },
  blueButton: {
    color: "#fff",
    height: "40px",
    borderRadius: "5px",
    backgroundColor: BgColor.myBlue,
    padding: "0 20px",
    "&:hover": {
      color: Color.textBlue,
      border: "1px solid" + Color.textBlue,
    },
  },
  orText: {
    color: Color.myBlack,
    fontSize: "18px",
    fontFamily: Fonts.Medium,
    opacity: ".5",
    justifyContent: "space-between",
  },
  payServiceDiv: {
    width: "100%",
    padding: "0px 0px",
    borderRadius: "0",
    backgroundColor: "#ECF2FF",
    boxShadow: "none",
    marginTop: "10px",
  },
  center: {
    display: "inline-block",
    position: "relative",
  },
  smallLightText: {
    color: BgColor.myBlack,
    fontSize: "14px",
    fontFamily: Fonts.Regular,
    opacity: 0.2,
  },
  overlayWrapper: {
    position: "fixed",
    top: "0",
    left: "0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100vh",
    zIndex: "2000",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  mainHeading2: {
    fontSize: "25px",
    fontFamily: Fonts.Medium,
  },
  whiteOutlinedButton: {
    width: "100%",
    height: "40px",
    color: Color.textBlue,
    fontFamily: Fonts.Medium,
    borderRadius: "5px",
    backgroundColor: "#fff",
    border: "2px solid " + BgColor.myBlue,
    padding: "0 20px",
    margin: "5px 0",
  },
  marginTop: {
    marginTop: "20px",
  },
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  chatArea: {
    flexGrow: 1,
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    scrollbarWidth: "none",
  },
  chatInputArea: {
    height: 55,
    border: `1px solid ${BgColor.myBlue}`,
    borderRadius: "1rem",
    display: "flex",
    alignItems: "center",
    padding: 10,
    gap: 10,
  },
  chatInput: {
    flexGrow: 1,
    height: "100%",
    border: "none",
    boxShadow: "none",
    outline: "none",
    color: Color.myBlack,
    fontSize: "16px",
    fontFamily: Fonts.Regular,
  },
  messageBubble: {
    border: `1px solid ${BgColor.myBlue}`,
    padding: "5px 10px",
    borderRadius: "0.5rem",
    maxWidth: "70%",
    width: "fit-content",
  },
  userMessage: {
    alignSelf: "flex-end",
    backgroundColor: BgColor.myBlue,
  },
  systemMessage: {
    alignSelf: "flex-start",
  },
  userMessageTime: {
    alignSelf: "flex-end",
    marginRight: "5px",
  },
  systemMessageTime: {
    alignSelf: "flex-start",
    marginLeft: "5px",
  },
  userMessageText: {
    textAlign: "start",
    color: "white",
  },
  systemMessageText: {
    textAlign: "start",
  },
  timeContainer: {
    marginBottom: "10px",
    marginTop: "2px",
  },
}));

const MessageBubble = (props) => {
  const classes = styles();

  return (
    <>
      <Box
        component={"div"}
        className={[
          classes.messageBubble,
          props.message.type == "system"
            ? classes.systemMessage
            : classes.userMessage,
        ]}
      >
        <Typography
          variant="body2"
          component={"p"}
          className={[
            props.message.type == "system"
              ? classes.systemMessageText
              : classes.userMessageText,
          ]}
          dangerouslySetInnerHTML={{ __html: props.message.text }}
        >
        </Typography>
      </Box>
      <Box
        component={"div"}
        className={[
          classes.timeContainer,
          props.message.type == "system"
            ? classes.systemMessageTime
            : classes.userMessageTime,
        ]}
      >
        <Typography
          variant="body2"
          component={"p"}
          style={{ fontSize: "12px" }}
        >
          {`${props.message.time.getHours()}:${props.message.time.getMinutes()}`}
        </Typography>
      </Box>
    </>
  );
};

  // FUNCTION to convert newLine lags to break tags
  const formatChatGPTResponse = (response) => {
    // Replace '**' with <strong> to make text bold
    response = response.replace(/\*\*(.*?)\*\*/g, "<b>$1</b><br/>");
    response = response.replace(/\*(.*?)\*/g, "<b>$1</b><br/>");

    // Replace newline characters with <br> tags for line breaks
    response = response.replace(/\n/g, "<br/>");

    // Convert lists (lines starting with '-' or numbers) to HTML list items
    response = response.replace(/(^- .*?$)/gm, "<li>$1</li>");
    response = response.replace(/(^[\d]+\. .*?$)/gm, "<li>$1</li>");

    // Wrap the entire response in <ul> tags to create an unordered list for bullet points
    response = `<ul>${response}</ul>`;

    // Replace headers with <h2> tags (assumes headers are in the format '*Header*')
    response = response.replace(/\\(.?)\\*/g, "<h2>$1</h2>");
    response = response.replace(
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi,
      "<a href='$1'>$1</a>"
    );

    return response;
  };

const Genie = () => {
  const { t, i18n } = useTranslation();
  const classes = styles();
  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userInput, setUserInput] = useState("");
  const messagesContainer = useRef(null);
  const authUser = useSelector((store) => store.auth.user);

  useEffect(() => {
    if (messagesContainer.current) {
      setTimeout(() => {
        messagesContainer.current.scrollTop =
          messagesContainer.current.scrollHeight + 10;
      }, 0);
    }
  }, [messages]);

  useEffect(() => {
    fetchMessages();
  }, [authUser]);

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        Const.BASE_URL + `get_genie_messages/${authUser?.id}`,
        axiosOptions(localStorage.userToken)
      );
      let userMessages = response.data.data;
      const formattedMessages = userMessages.map((message) => ({
        type: message.type,
        text: formatChatGPTResponse(message.message),
        raw_text: message.message,
        time: new Date(message.created_at),
      }));
      setMessages(formattedMessages);
    } catch (error) {
      let err = error.response ? error.response.data : error;
      console.log("Error in genie messages:", err);
    }
  };

  const handleUserInput = async () => {
    if (userInput.trim() !== "" && !loading) {
      const newMessage = {
        text: userInput,
        type: "user",
        time: new Date(),
      };

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setUserInput("");
      setLoading(true);
      // let messageArr = prevMessages.map((elem) => {
      //   return {role: elem.role, text: elem.text};
      // });
      let newArr = messages.map((elem) => {
        return {role: elem.type, content: elem.raw_text};
      })
      newArr.push({
        role: "system",
        content: newMessage.text,
      });
      newArr = newArr.splice(-4);
     
     
      try {
        const response = await axios.post(
          "https://irevu.openai.azure.com/openai/deployments/irevu4o0/chat/completions?api-version=2024-02-15-preview",
          {
            messages: newArr,
            max_tokens: 650,
            temperature: 1,
            frequency_penalty: 0,
            presence_penalty: 0,
            // top_p: 0.5,
            stop: null,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": "7327443ae85e44419192e4fb0544d702",
            },
          }
        );

        if (response?.data?.usage?.total_tokens) {
          const requestData = {
            user_id: authUser.id,
            words_lenght: response?.data?.usage?.total_tokens,
            user_message: newMessage.text,
            system_message: response.data.choices[0].message.content,
          };
          const updateTokens = await axios
            .post("https://api.irevu.org/ask_question_ai", requestData)
            .then((result) => {
              
              if (result?.data?.status === "false") {
                // Show plan expiry error
                alert(result?.data?.message);
              } else if (result?.data?.status === "true") {
                let resp = response.data.choices[0].message.content;
                
                const systemMessage = {
                  type: "system",
                  text: formatChatGPTResponse(resp),
                  raw_text: resp,
                  time: new Date(result.data.data.created_at)
                };

                setMessages((prevMessages) => [...prevMessages, systemMessage]);
                setLoading(false);
              }
            })
            .catch((err) => {
              console.log("Backend error: ", err);
            });
        }
      } catch (error) {
        console.log("Error fetching Genie Response: ", error);
      }
    }
  };

  return (
    <>
      <Header history={history} />
      <Container
        maxWidth="md"
        className={[classes.container, classes.mainContainer]}
      >
        <Box
          component={"div"}
          className={classes.chatArea}
          ref={messagesContainer}
        >
          {messages.map((message, index) => (
            <MessageBubble key={index} message={message} />
          ))}
        </Box>
        <Box component={"div"} className={classes.chatInputArea}>
          <input
            type="text"
            className={classes.chatInput}
            value={userInput}
            onChange={(event) => setUserInput(event.target.value)}
            onKeyDown={(event) => event.key === "Enter" && handleUserInput()}
            placeholder="Write your message"
          />
          <Box onClick={() => handleUserInput()}>
            <Send style={{ color: BgColor.myBlue }} />
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default Genie;
